$(function () {
  $(document).ready(function() {
    var sp_control = 0;
    var hamName = document.getElementById("spmenu");

    $(".ham,.closebtn").click(function(){
      menuOpen();
    });
    
    $(".close").click(function(){
      $("#alert").remove();
    });
    
    function menuOpen(){
      if(sp_control == 0) {
        hamName.style.display = 'block';
        hamName.animate([{opacity: '0'}, {opacity: '1'}], 300)
        sp_control = 1;
      } else {
        hamName.animate([{opacity: '1'}, {opacity: '0'}], 100)
        hamName.style.display = 'none';
        sp_control = 0;
      }
    }
    
    $('.check').click(function(){
      hamName.animate([{opacity: '1'}, {opacity: '0'}], 100)
      hamName.style.display = 'none';
      sp_control = 0;
      
      $('#modalArea').fadeIn();
    });
    
    $('#closeModal, #modalBg').click(function(){
      $('#modalArea').fadeOut();
    });
    
  });
});